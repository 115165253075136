<template>
  <v-app>
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner2 pt-6">
        <Overlays :is-absolute="true" :is-show="absoluteOverlayStatus"></Overlays>
        <v-card class="my-12 px-6 py-6">
          <div class="d-flex justify-center mb-9" style="font-size: 20px; font-weight: bold">
            <span>会員情報</span>
          </div>
          <template v-if="apierror.status == 'error'">
            <div v-for="msg of apierror.messages" :key="msg">
              <v-row class="ml-6 mb-3 ma-3">
                <span style="color: red">* {{ msg }} </span>
              </v-row>
            </div>
          </template>
          <v-row>
            <v-col style="font-size: 16px">
              <div class="mb-3" style="font-weight: bold">
                <span>お名前：</span>
              </div>
              <div v-if="info.mbr_sei_kana || info.mbr_mei_kana">
                <span>({{ info.mbr_sei_kana }} {{ info.mbr_mei_kana }})</span>
              </div>
              <div>
                <span>{{ info.mbr_sei }} {{ info.mbr_mei }}</span>
              </div>
            </v-col>
            <v-col class="d-flex justify-end"> </v-col>
          </v-row>
          <v-divider class="mt-3 mb-6"></v-divider>
          <v-row>
            <v-col style="font-size: 16px">
              <div class="mb-3" style="font-weight: bold">
                <span>住所：</span>
              </div>
              <div>
                <span>〒 {{ info.mbr_zip }}</span>
              </div>
              <div>
                <span>{{ info.mbr_address1 }}</span>
              </div>
              <div v-if="info.mbr_address2 !== null">
                <span>{{ info.mbr_address2 }}</span>
              </div>
            </v-col>
            <v-col class="d-flex justify-end">
              <v-btn depressed color="#ff6e40" @click="address">
                <span style="color: #fff">変更</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-6"></v-divider>
          <v-row>
            <v-col style="font-size: 16px">
              <div class="mb-3" style="font-weight: bold">
                <span>Eメール:</span>
              </div>
              <div>
                <span>{{ info.mbr_email }}</span>
              </div>
            </v-col>
            <v-col class="d-flex justify-end"></v-col>
          </v-row>
          <v-divider class="mt-3 mb-6"></v-divider>
          <v-row style="font-size: 16px">
            <v-col>
              <div class="mb-3" style="font-weight: bold">
                <span>電話番号：</span>
              </div>
              <div>
                <span>{{ info.mbr_mobile }}</span>
              </div>
            </v-col>
            <v-col class="d-flex justify-end">
              <v-btn depressed color="#ff6e40" @click="mobile">
                <span style="color: #fff">変更</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-6"></v-divider>
          <v-row>
            <v-col style="font-size: 16px">
              <div class="mb-3" style="font-weight: bold">
                <span>パスワード：</span>
              </div>
              <div>
                <span>********</span>
              </div>
            </v-col>
            <v-col class="d-flex justify-end">
              <v-btn depressed color="#ff6e40" @click="password">
                <span style="color: #fff">変更</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-6"></v-divider>
          <v-row>
            <v-col style="font-size: 16px">
              <div class="mb-3" style="font-weight: bold">
                <span>備考：</span>
              </div>
              <div v-if="info.mbr_nickname">
                <span>{{ info.mbr_nickname }}</span>
              </div>
              <div v-else style="height:24px;">
              </div>
            </v-col>
            <v-col class="d-flex justify-end">
              <v-btn depressed color="#ff6e40" @click="toDescriptionEdit">
                <span style="color: #fff">変更</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-6"></v-divider>
          <div class="mt-10">
            <v-btn depressed style="width: 180px; background-color: #ff6e40" @click="dialog = true">
              <span style="color: #fff">退会手続きへ</span>
            </v-btn>
          </div>
          <div class="d-flex justify-end mt-12">
            <v-btn depressed color="#ff6e40" width="180px" class="px-12" @click="back">
              <span style="color: #fff">ホームに戻る</span>
            </v-btn>
          </div>
          <v-dialog v-model="dialog" persistent width="600px">
            <v-card class="d-flex align-center justify-center px-6 pt-12 pb-10">
              <div>
                <div style="text-align: center; font-weight: bold; font-size: 20px">
                  <span>退会の前にご確認ください</span>
                </div>
                <div class="mt-8 mb-10" style="text-align: left; font-size: 16px">
                  <div class="mt-2">
                    <span>※退会すると所持している注文履歴などすべて削除されます。</span>
                  </div>
                  <div class="mt-2">
                    <span>
                      ※退会手続きをされても、7営業日（年末年始・土日祝日以外の日）以内に発送予定日を迎えるご注
                      文（定期便を含む）の取り消しは致しかねますので、予めご了承ください。</span>
                  </div>
                  <div class="mt-2">
                    <span>※退会後30日間は再登録することはできません。</span>
                  </div>
                </div>
                <v-row class="mt-8" no-gutters>
                  <v-col>
                    <div v-if="!$vuetify.breakpoint.smAndUp" class="d-flex justify-center mt-3">
                      <v-btn color="#ff6e40" width="200px" depressed :loading="loading" @click="cancel">
                        <span style="color: #fff">はい、退会します</span>
                      </v-btn>
                    </div>
                    <div v-if="$vuetify.breakpoint.smAndUp" class="d-flex justify-center mt-3">
                      <v-btn width="200px" outlined depressed @click="dialog = false">
                        <span>いいえ、退会しません</span>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col>
                    <div v-if="!$vuetify.breakpoint.smAndUp" class="d-flex justify-center mt-3">
                      <v-btn width="200px" outlined depressed @click="dialog = false">
                        <span>いいえ、退会しません</span>
                      </v-btn>
                    </div>
                    <div v-if="$vuetify.breakpoint.smAndUp" class="d-flex justify-center mt-3">
                      <v-btn color="#ff6e40" width="200px" depressed :loading="loading" @click="cancel">
                        <span style="color: #fff">はい、退会します</span>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-dialog>
        </v-card>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Overlays from '@/components/Overlays.vue'

export default {
  components: {
    Overlays,
  },
  data: () => ({
    dialog: false,
    loading: false,
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
  }),

  computed: {
    ...mapState('app', ['absoluteOverlayStatus']),
    ...mapState('auth', ['info']),
  },

  created() {
    this.loadData()
  },
  methods: {
    ...mapMutations('cartStore', ['clearCart']),
    ...mapMutations('auth', ['clearInfo']),
    ...mapMutations('app', ['setAbsoluteOverlayStatus', 'setOverlayStatus']),
    ...mapActions('auth', ['loadInfo', 'CancelMembership']),

    loadData() {
      this.setAbsoluteOverlayStatus(true)
      this.loadInfo().then(() => {
        console.log('loadInfo', this.info)
        this.setAbsoluteOverlayStatus(false)
      })
    },
    cancel() {
      this.loading = true
      this.CancelMembership()
        .then(() => {
          this.clearCart()
          this.$store.dispatch('auth/logout')
          this.clearInfo()
        })
        .catch(error => {
          this.apierror.status = 'error'
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        })
        .finally(() => {
          this.loading = false
          this.$router.push('/')
        })
    },
    address() {
      this.$router.push('/changeaddress')
    },
    mobile() {
      this.$router.push('/changemobile')
    },
    password() {
      this.$router.push('/changepassword')
    },
    toDescriptionEdit() {
      this.$router.push('/changedescription')
    },
    back() {
      this.$router.push('/')
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
